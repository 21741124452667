import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layout.js";
import Head from "../../../../components/head";
import { Container, Wrapper } from "../../../../components/layout/element";
import Productinfopage from "../../../../components/productinfopage/productinfopage";
import { FlowChartImg } from "../../../../components/productinfopage/productinfopage";
import AvailabilityChart from "./Availablity-chart-1.png";
import FlowChart from "./flowchart-native.png";
import ProductlistSub from "./productlistSub.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Wrapper topmargin bottommargin topmarginmobile mdxType="Wrapper">
    <Productinfopage title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} cultivation={props.pageContext.frontmatter.cultivation} hscode={props.pageContext.frontmatter.hscode} category={props.pageContext.frontmatter.category} imgageprop={AvailabilityChart} mdxType="Productinfopage" />
  
        <br />
        <h3>{`General Information`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Specification`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Value`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Origin`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tamil Nadu, India`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Maturity`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`90 – 95%`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`Shelf Life`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`5 days at controlled temperature`}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h3>{`Procurement and Processing of Vegetables:`}</h3>
        <FlowChartImg src={FlowChart} alt="product photo" mdxType="FlowChartImg" />
        <br />
        <h3>{`All Vegetables – At a glance`}</h3>
        <ProductlistSub mdxType="ProductlistSub" />
  </Wrapper>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      